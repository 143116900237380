import React, {useState} from 'react';
import logo from '../Assets/alamaitlogo.png';
//import {BsCart2} from 'react-icons/bs';  //
import {HiOutlineBars3} from 'react-icons/hi2';
import { 
    Box,
    Drawer,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
//import DoneOutlineIcon from '@mui/icons-material/DoneOutline';//
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';
//import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';//
import { Link } from 'react-scroll';



const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false)
    const menuOptions= [
        {
            text: 'Home',
            icon: <HomeIcon />,
            
        },
        {
            text: 'About',
            icon: <InfoIcon />,
            
        },
        /*{
            text: 'Projects',
            icon: <DoneOutlineIcon />,
        },*/
        {
            text: 'Contact',
            icon: <PhoneRoundedIcon />,
        },
        /*{
            text: 'Business Solutions',
            icon: <ShoppingCartRoundedIcon />,
        },*/
    ]
  return(
    <nav>
        <div className="nav-logo-container">
            <img src={logo} alt="" />
        </div>
        <div className="navbar-links-container">
            <Link className='nav-link'activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={500}>Home</Link>
            <Link className='nav-link'activeClass="active" to="about" spy={true} smooth={true} offset={-70} duration={500}>About</Link>
            <Link className='nav-link'activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={500}>Contact Us</Link>
            {/*<Link className='nav-link'activeClass="active" to="projects" spy={true} smooth={true} offset={-70} duration={500}>Projects</Link>
            <Link className='nav-link'activeClass="active" to="business-solutions" spy={true} smooth={true} offset={-70} duration={500}>BusinessSolutions</Link>*/}
            <button className="primary-button">
                <a href="tel:+263780053056">
                    Call Us Now
                </a>
            </button>
        </div>
        <div className="navbar-menu-container">
            <HiOutlineBars3 onClick= {() => setOpenMenu (true)} />
        </div>
        <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor='right'>
            <Box sx={{width: 250}}
                role="presentation"
                onClick = {() => setOpenMenu (false)}
                onKeyDown = {() => setOpenMenu (false)}
            >
                <list>
                    {menuOptions.map((item) => (
                        <ListItem key={item.text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <Link activeClass="active" to={item.text.toLowerCase()} spy={true} smooth={true} offset={-70} duration={500}>
                                    <ListItemText primary={item.text} />
                                </Link>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </list>
            </Box>
        </Drawer>
    </nav>
  );
  
};

export default Navbar;