import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FaBuilding } from 'react-icons/fa';
import { useState, useEffect } from 'react';

const Testimonial = () => {
    const boxes = [
        { title: 'St Kilda Housing', description: 'Student housing project managed by Alamait, this project mainly focuses on addressing student hosuing issues, in Mt Pleasant. Premier location and top class facilities, One of our large scale projects. Project is mainly set to solve the problem of student accommodation at the University of Zimbabwe', link: 'tel:+263780053056', icon: <FaBuilding color='purple'/>, color: 'purple'},
        { title: 'Belvedere Housing', description: 'Student housing project managed by Alamait, this project mainly focuses on addressing student hosuing issues, in Belvedere. Premier location and top class facilities, One of our large scale projects.', link: 'tel:+263780053056', icon: <FaBuilding color='orange'/>, color: 'orange'},
        { title: 'Nevsky Properties', description: 'Many assets under management, retail stores and many commercial rental units. Strategic locations and top notch and well maintained facilities.  For all state of the art units and propertiies, we are here.', link: 'tel:+263780053056', icon: <FaBuilding color='red' />, color: 'red'},//
    ];
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth < 768);
        };
      
        window.addEventListener('resize', handleResize);
        handleResize();
      
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  return (
    <div className='work-section-wrapper'>
      <div className='work-section-top'>
        <p className='primary-subheading'>Clients</p>
        <h1 className='primary-heading'>Our Bussiness</h1>
        <p className='primary-text'>
          Here are some of the clients we took on, and what we are doing for and with them.
        </p>
      </div>
      <div >
        {isSmallScreen ? (
            <Carousel showThumbs={false} showStatus={false} infiniteLoop useKeyboardArrows autoPlay>
                {boxes.map((box, i) => (
                <div className='box' key={i}>
                    {box.icon}
                    <h2 className='box-heading'>{box.title}</h2>
                    <p className='box-description'>{box.description}</p>
                    <a href={box.link} style={{color: box.color}} className='box-link'>Learn More</a>
                </div>
                ))}
            </Carousel>
            ) : (
            <div className='box-section'>
                {boxes.map((box, i) => (
                <div className='box' key={i}>
                    {box.icon}
                    <h2 className='box-heading'>{box.title}</h2>
                    <p className='box-description'>{box.description}</p>
                    <a href={box.link} style={{color: box.color}} className='box-link'>Learn More</a>
                </div>
                ))}
            </div>
            )}
      </div>
    </div>
  );
};

export default Testimonial;