import './App.css';
import Home from './Components/Home';
import About from './Components/About';
//import Testimonial from './Components/Testimonial';//
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import { Element } from 'react-scroll';
import Projects from './Components/Projects';
//import Samples from './Components/Samples';//
//import Sample from './Components/BusinessSolutions';//


function App() {
  return (
    <div className="App">
      <Element name='home'>
        <Home />
      </Element>
      <Element name='about'>
        <About />
      </Element>
      <Element name='projects'>
        <Projects />
      </Element>
      {/*<Element name='business-solutions'>
        <Sample />
      </Element>*/}
      <Element name='contact'>
        <Contact />
      </Element>
      <Footer />
    </div>
  );
}

export default App;
