import React from 'react';

const Contact = () => {
  return (
    <div className='contact-page-wrapper'>
      <h1 className='primary-heading'>Have A Question In Mind</h1>
      <h1 className='primary-heading'>Let Us Help You</h1>
      <div className='contact-form-container'>
        <form>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input type='text' id='name' placeholder='Your Name' />
          </div>
          <div className='form-group'>
            <label htmlFor='companyName'>Company Name</label>
            <input type='text' id='companyName' placeholder='Your Company Name' />
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input type='email' id='email' placeholder='youremail@gmail.com' />
          </div>
          <div className='form-group'>
            <label htmlFor='contactNumber'>Contact Number</label>
            <input type='tel' id='contactNumber' placeholder='Your Contact Number' />
          </div>
          <div className='form-group'>
            <label htmlFor='inquiry'>Inquiry</label>
            <textarea id='inquiry' placeholder='Your Inquiry' />
          </div>
          <button type='submit' className='secondary-button'>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;