import React from 'react'
//import AboutBackground from '../Assets/about-background.png';//
import AboutBackgroundImage from '../Assets/abouttt.jpg';
//import { BsFillPlayCircleFill } from 'react-icons/bs';//


const About = () => {
  return (
    <div className='about-section-container'>
      {/*<div className='about-background-image-container'>
        <img src={AboutBackground} alt='' />
      </div>*/}
      <div className='about-section-image-container'>
        <img src= {AboutBackgroundImage} alt='' className='abouttt' />
      </div>
      <div className='about-section-text-container'>
        <p className='primary-subheading'>About</p>
        <h1 className='primary-heading'>
          Your Trusted Property and Resource Management Partner
        </h1>
        <p className='primary-text'>
          A team of experts that provide the professional, human resource and
          property management expertise. We provide a whole team and worth-while service 
          at ease. With our team and experience, we will build your dream.
        </p>
      </div>
    </div>
  )
}

export default About;