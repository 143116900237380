import React from 'react';
import Navbar from './Navbar';
//import BannerBackground from '../Assets/home-banner-background.png';//
import BannerImage from '../Assets/house.jpg';
// import { FiArrowRight } from 'react-icons/fi';//
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { FaBuilding, FaUsers, FaChartLine} from 'react-icons/fa'; //import icons
import { useMediaQuery } from 'react-responsive';



const Home = () => {
  const boxes = [
    { title: 'Property Management', description: 'For all your property management solutions, Alamait is a trusted partner, that will manage and build your dream.', link: 'tel:+263780053056', icon: <FaBuilding color='purple'/>, color: 'purple'},
    { title: 'Human Resources', description: 'For all your human resource personnel and management, Alamait should be your only trusted partner. Skilled and experienced personnel.', link: 'tel:+263780053056', icon: <FaUsers color='orange'/>, color: 'orange'},
    { title: 'Investment Management', description: 'Years of experience of investment, skilled personnel. Millions in assets under management, and strategic partnerships.', link: 'tel:+263780053056', icon: <FaChartLine color='red' />, color: 'red'},
  ];
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
  return (
    <div className='home-container'>
      <Navbar />
      <div className='home-banner-container'>
        {/*<div className='home-bannerImage-container'>
          <img src={BannerBackground} alt='' />
        </div>*/}
        <div className='home-text-section'>
          <h1 className='primary-heading'>
            Management and Exellence at it's best
          </h1>
          <p className='primary-text'>
            Welcome to Alamait, your premier destination for all human resource 
            needs, and property management, and excellence at it's best. 
          </p>
          {/*<button className='secondary-button'>
            Order Now <FiArrowRight />
          </button>*/}
        </div>
        <div className='home-image-container'>
          <img src={BannerImage} alt='' className='home-img'/>
        </div>
      </div>
      {isSmallScreen ? (
          <Carousel showThumbs={false} showStatus={false} infiniteLoop useKeyboardArrows autoPlay>
            {boxes.map((box, i) => (
              <div className='box' key={i}>
                {box.icon}
                <h2 className='box-heading'>{box.title}</h2>
                <p className='box-description'>{box.description}</p>
                <a href={box.link} style={{color: box.color}} className='box-link'>Learn More</a>
              </div>
            ))}
          </Carousel>
        ) : (
          <div className='box-section'>
            {boxes.map((box, i) => (
              <div className='box' key={i}>
                {box.icon}
                <h2 className='box-heading'>{box.title}</h2>
                <p className='box-description'>{box.description}</p>
                <a href={box.link} style={{color: box.color}} className='box-link'>Learn More</a>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};


export default Home;