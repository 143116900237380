import React from 'react';
import Logo from '../Assets/alamaitlogo.png';
import { BsTwitter } from 'react-icons/bs';
import { SiLinkedin } from 'react-icons/si';
//import { BsYoutube } from 'react-icons/bs';//
import { FaFacebookF } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer-wrapper'>
        <div className='footer-section-one'>
            <div className='footer-logo-container'>
                <img src={Logo} alt='' />
            </div>
            <div className='footer-icons'>
                <BsTwitter />
                <SiLinkedin />
                {/*<BsYoutube />*/}
                <FaFacebookF />
            </div>
        </div>
        <div className='footer-section-two'>
            <div className='footer-section-columns'>
                <span>Quality</span>
                <span>Exellence</span>
                <span>Management</span>
                <span>Investment</span>
                <span>Integrity</span>
            </div>
            <div className='footer-section-columns'>
                <span>
                    <a href="tel:+263780053056">
                        +263-78-005-3056
                    </a>
                </span>
                <span>No.1 Ardmore close Greendale</span>
                <span>support@alamait.com</span>
                <span>hr@alamait.com</span>
            </div>
            {/*<div className='footer-section-columns'>
                <span>Terms & Conditions</span>
                <span>Privacy Policy</span>
            </div>*/}
        </div>
    </div>
  );
};

export default Footer;